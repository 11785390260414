import { SVGProps } from "react";

export default function Type2(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      id="svg2"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 32 32"
      xmlSpace="preserve"
      {...props}
    >
      <style type="text/css">
        {
          "\n\t.st0{fill:none;stroke:#000000;stroke-width:2.5;stroke-miterlimit:10;}\n"
        }
      </style>
      <g id="layer1">
        <ellipse id="ellipse4206" cx={19.7} cy={21.8} rx={2} ry={2} />
        <ellipse id="ellipse4208" cx={12.1} cy={21.8} rx={2} ry={2} />
        <ellipse id="ellipse4210" cx={8.6} cy={15.6} rx={2} ry={2} />
        <ellipse id="ellipse4212" cx={15.9} cy={15.6} rx={2} ry={2} />
        <ellipse id="ellipse4214" cx={23.2} cy={15.6} rx={2} ry={2} />
        <ellipse id="ellipse4216" cx={19.7} cy={9.7} rx={2} ry={2} />
        <ellipse id="ellipse4218" cx={12.5} cy={9.7} rx={2} ry={2} />
        <path
          className="st0"
          d="M26.3,5c2.5,2.9,4,6.9,3.4,11.2c-0.8,6.3-5.9,11.2-12.1,11.9C9.2,29,2.1,22.4,2.1,14.2c0-3.6,1.4-6.8,3.6-9.2 c0.7-0.7,1.6-1.1,2.5-1.1h15.7C24.7,3.9,25.7,4.2,26.3,5z"
        />
      </g>
    </svg>
  );
}
