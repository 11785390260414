import { SVGProps } from "react";

export default function CHADEMO(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      id="svg2"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 32 32"
      xmlSpace="preserve"
      {...props}
    >
      <style type="text/css">
        {
          "\n\t.st0{fill:none;stroke:#000000;stroke-width:2.5;stroke-linejoin:round;stroke-miterlimit:3.2;}\n"
        }
      </style>
      <g id="layer1">
        <ellipse id="path4213" cx={21.6} cy={16.1} rx={2} ry={2} />
        <ellipse id="ellipse4215" cx={10.3} cy={16.1} rx={2} ry={2} />
        <g id="g4234">
          <circle id="path4221" cx={15.9} cy={9.9} r={2} />
        </g>
        <g id="g4248" transform="translate(2.9325188e-8,350)">
          <circle id="circle4250" cx={15.9} cy={-327.8} r={2} />
        </g>
        <path
          id="path4271"
          className="st0"
          d="M8.9,5.9c0,0,2.1-1.1,3.3-1.5c1.1-0.4,2.3-0.6,3.5-0.6c1.4,0,2.7,0.2,4,0.6 C20.9,4.8,23,5.9,23,5.9l1.5-0.4l2.2,2.2l-0.5,1.6c0,0,1.2,2.2,1.6,3.5c0.3,1.1,0.5,2.3,0.5,3.5c-0.1,1.4-0.4,2.7-0.9,4 c-0.4,1.1-1,2.1-1.7,3c-0.7,0.9-1.5,1.7-2.4,2.4c-0.8,0.6-1.5,1.1-2.4,1.5c-0.8,0.4-1.8,0.6-2.7,0.8c-0.8,0.2-1.5,0.2-2.2,0.2 c-0.8,0-1.5-0.1-2.2-0.2c-0.8-0.2-1.5-0.3-2.2-0.6c-0.8-0.3-1.5-0.7-2.3-1.1c-0.9-0.6-1.9-1.4-2.7-2.2c-0.8-0.8-1.5-1.8-2-2.9 c-0.5-1-0.7-2-0.9-3.1c-0.2-1.1-0.2-2.1-0.2-3.2s0.4-2.1,0.7-3.1c0.4-1,1.1-2.6,1.1-2.6L5.1,7.5l2-2L8.9,5.9z"
        />
      </g>
    </svg>
  );
}
