import { ReactNode, useEffect, useMemo, useState } from "react";
import { useT } from "@utils/translation";
import { ConnectorStatus } from "@utils/types/ConnectorStatus.enum";
import {
  ArrowRight,
  Clock,
  Coins,
  Info,
  Lightning,
  Sigma,
} from "@phosphor-icons/react";
import Button from "@components/Button";
import Spinner from "@components/Spinner";
import { formatNumber } from "@utils/formatNumber";
import clsx from "clsx";
import Message from "@components/toasts/Message";
import StopChargingConfirmation from "./StopChargingConfirmation";
import { useStation } from "../Station/state";
import { useChargingProcess } from "./state";
import moment from "moment";

export default function ChargingInfo() {
  const { t } = useT();
  const connector = useStation((s) => s.connector);
  const isStationLoading = useStation((s) => s.isStationLoading);
  const chargingProcessId = useChargingProcess((s) => s.chargingProcessId);
  const chargingInfo = useChargingProcess((s) => s.chargingInfo);
  const hasFinishedCharging = useChargingProcess((s) => s.hasFinishedCharging);
  const dismissChargingInfo = useChargingProcess((s) => s.dismissChargingInfo);
  const processChargingProcess = useChargingProcess(
    (s) => s.processChargingProcess
  );
  const stopCharging = useChargingProcess((s) => s.stopCharging);
  const isStoppingCharging = useChargingProcess((s) => s.isStoppingCharging);
  const isFree = Boolean(connector?.priceInfo.isFree);

  const [askingForStoppingConfirmation, setAskingForStoppingConfirmation] =
    useState(false);

  const isCharging =
    chargingProcessId && connector?.status === ConnectorStatus.CHARGING;

  const showInvoiceMessage = chargingInfo?.chargingCosts != 0;

  const { startTime, endTime, duration } = useMemo(() => {
    if (!chargingInfo?.startDate) {
      return {};
    }

    const start = moment(chargingInfo?.startDate);
    const end = moment(chargingInfo?.endDate);
    const dur = moment.duration(end.diff(start));
    const days = dur.days();
    const hours = dur.hours();
    const duration = `${days > 0 ? days + "d " : ""}${
      hours > 0 || days > 0 ? hours + "h " : ""
    }${dur.minutes()}m`;

    const isDayAfter = end.isAfter(start, "day");
    const startTime = start.format("HH:mm") + (isDayAfter ? ` (-1)` : "");
    const endTime = end.format("HH:mm");

    return {
      startTime,
      endTime,
      duration,
    };
  }, [chargingInfo]);

  const onStopCharging = () => {
    setAskingForStoppingConfirmation(false);
    stopCharging();
  };

  const onAskForStopConfirmation = () => {
    setAskingForStoppingConfirmation(true);
  };

  useEffect(() => {
    if (chargingProcessId && !isStationLoading) {
      processChargingProcess();
    }
  }, [chargingProcessId, processChargingProcess, isStationLoading]);

  return (
    <div className="px-1 pb-1">
      <div
        className={clsx("flex flex-col self-stretch rounded bg-pale-smoke", {
          hidden: chargingProcessId === undefined || isStationLoading,
        })}
      >
        {!chargingInfo && (
          <div className="flex-1 pt-4 flex justify-center">
            <Spinner />
          </div>
        )}

        {chargingInfo && !chargingInfo.costBreakdown && (
          <div className="flex flex-col">
            <InfoRow
              label={t("chargedEnergy")}
              value={formatNumber(
                chargingInfo.energyConsumption,
                { max: 2 },
                "kWh"
              )}
              subLabel={t("chargingPower")}
              subValue={
                chargingInfo.power
                  ? formatNumber(chargingInfo.power, { max: 2 }, "kW")
                  : undefined
              }
              icon={<Lightning className="text-lg" weight="bold" />}
            />

            {Boolean(startTime) && (
              <InfoRow
                label={t("startTime")}
                value={startTime}
                subLabel={t("duration")}
                subValue={duration}
                icon={<Clock className="text-lg" weight="bold" />}
              />
            )}

            {Boolean(chargingInfo?.chargingCosts) && (
              <InfoRow
                label={t("chargingCost")}
                value={formatNumber(
                  chargingInfo.chargingCosts,
                  { max: 2, min: 2 },
                  "€"
                )}
                subLabel={t("duration")}
                subValue={duration}
                icon={<Coins className="text-lg" weight="bold" />}
              />
            )}
          </div>
        )}

        {Boolean(chargingInfo?.costBreakdown) && (
          <div className="flex flex-col">
            <div className="flex justify-between px-5 pt-4 pb-1 text-sm text-medium-smoke">
              <div className="font-medium">{t("summary")}</div>

              <div
                className={clsx("flex items-center gap-1 font-normal", {
                  hidden: !Boolean(startTime) || !Boolean(endTime),
                })}
              >
                {startTime}
                <ArrowRight weight="bold" className="text-xs" />
                {endTime}
              </div>
            </div>

            <CostBreakdownRow
              icon={<Lightning className="text-lg" weight="bold" />}
              label={t("chargedEnergy")}
              value={formatNumber(
                chargingInfo?.energyConsumption,
                { max: 2 },
                "kWh"
              )}
              cost={chargingInfo?.costBreakdown?.energy}
            />

            <CostBreakdownRow
              icon={<Clock className="text-lg" weight="bold" />}
              label={t("costBreakdown.blocking")}
              value={duration}
              cost={chargingInfo?.costBreakdown?.blocking}
            />

            <CostBreakdownRow
              icon={<Coins className="text-lg" weight="bold" />}
              label={t("costBreakdown.flat")}
              cost={chargingInfo?.costBreakdown?.flat}
            />

            <CostBreakdownRow
              icon={<Sigma className="text-lg" weight="bold" />}
              label={t("costBreakdown.total")}
              cost={chargingInfo?.costBreakdown?.total}
              isTotal
            />

            <div className="flex gap-2 justify-end pr-5 text-xs font-medium text-medium-smoke">
              <span>{t("costBreakdown.reserved")}</span>
              <span>
                {formatNumber(
                  chargingInfo?.costBreakdown?.reserved,
                  { min: 2, max: 2 },
                  "€"
                )}
              </span>
            </div>
          </div>
        )}

        <div className="p-4 pt-2 flex flex-col gap-2">
          {hasFinishedCharging ? (
            <>
              {showInvoiceMessage && (
                <Message
                  size="sm"
                  color="yellow"
                  message={t("infoMessageFinished")}
                  icon={Info}
                />
              )}

              <Button
                color="bordered"
                className="justify-center"
                onClick={dismissChargingInfo}
              >
                {t("actionButtonLabelDismiss")}
              </Button>
            </>
          ) : connector?.status === ConnectorStatus.FINISHING ? (
            <Message
              color="yellow"
              size="sm"
              message={
                t("infoMessageFinishing") +
                (!isFree ? " " + t("infoMessageFinishingInvoice") : "")
              }
              icon={Info}
            />
          ) : (
            <Button
              disabled={!isCharging || isStoppingCharging}
              color="red"
              className="justify-center w-full"
              onClick={onAskForStopConfirmation}
            >
              {isCharging ? (
                <>
                  {isStoppingCharging && <Spinner />}
                  {t("actionButtonLabelStop")}
                </>
              ) : (
                <>{t("gettingChargingInfo")}...</>
              )}
            </Button>
          )}
        </div>

        <StopChargingConfirmation
          open={askingForStoppingConfirmation}
          onClose={() => setAskingForStoppingConfirmation(false)}
          onConfirm={onStopCharging}
        />
      </div>
    </div>
  );
}

interface InfoRowProps {
  label?: ReactNode;
  value?: ReactNode;
  subLabel?: ReactNode;
  subValue?: ReactNode;
  icon?: ReactNode;
}

function InfoRow({ label, value, subLabel, subValue, icon }: InfoRowProps) {
  return (
    <div className="flex-1 flex gap-1 pr-5 py-3 border-b-2 last:border-b-0 border-b-light-smoke">
      <div className="flex justify-center w-14 pt-1">{icon}</div>

      <div className="flex flex-col gap-1 flex-1">
        <div className="flex justify-between">
          <span className="font-medium">{label}</span>
          <span className="font-semibold">{value}</span>
        </div>

        {subValue !== undefined && (
          <div className="flex justify-between text-sm font-semibold text-medium-smoke">
            <span>{subLabel}</span>
            <span>{subValue}</span>
          </div>
        )}
      </div>
    </div>
  );
}

interface CostBreakdownRowProps {
  icon: ReactNode;
  label: ReactNode;
  value?: ReactNode;
  cost?: number;
  isTotal?: boolean;
}

function CostBreakdownRow({
  icon,
  label,
  value,
  cost,
  isTotal,
}: CostBreakdownRowProps) {
  return (
    <div
      className={clsx("flex-1 flex gap-1 pr-5 py-3 border-b-light-smoke", {
        "border-b-2": !isTotal,
      })}
    >
      <div className="flex justify-center w-14 pt-1">{icon}</div>

      <div className="font-semibold flex-1">{label}</div>

      <div className="flex text-right gap-1">
        {value !== undefined && (
          <div className="font-semibold text-medium-smoke">{value}</div>
        )}

        <div
          className={clsx("font-semibold min-w-28", {
            "text-xl": isTotal,
          })}
        >
          {formatNumber(cost, { min: 2, max: 2 }, "€")}
        </div>
      </div>
    </div>
  );
}
