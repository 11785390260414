import clsx from "clsx";
import { HTMLProps } from "react";

interface Props extends HTMLProps<HTMLDivElement> {
  color?: "orange" | "green" | "red" | "blue" | "gray";
}

export default function Badge({
  color = "gray",
  className,
  children,
  ...props
}: Props) {
  return (
    <div
      className={clsx(
        "py-0.5 md:py-1 px-3 rounded-full text-sm font-semibold",
        {
          "text-white": color !== "gray",
          "bg-medium-orange": color === "orange",
          "bg-medium-green": color === "green",
          "bg-medium-berry": color === "red",
          "bg-medium-aqua": color === "blue",
          "bg-gray-100 text-gray-500": color === "gray",
        },
        className
      )}
      {...props}
    >
      {children}
    </div>
  );
}
