export default function WarningIndicator() {
  return (
    <svg
      width="25"
      height="26"
      viewBox="0 0 25 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="animate-bounce shrink-0"
    >
      <circle cx="11" cy="14.5" r="11" fill="#96ACC3" />
      <circle cx="13.3013" cy="11.5" r="10" stroke="black" strokeWidth="2" />
      <path
        d="M13.3013 5.5V13.5"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <circle cx="13.3013" cy="17.5" r="1" fill="black" />
    </svg>
  );
}
