import { ConnectorStatus } from "@utils/types/ConnectorStatus.enum";
import { ConnectorType } from "@utils/types/ConnectorType.enum";
import { MapPin, Warning } from "@phosphor-icons/react";
import clsx from "clsx";
import { HTMLProps, useEffect, useMemo } from "react";
import CHADEMO from "./connectorTypeIcons/CHADEMO";
import CCS from "./connectorTypeIcons/CCS";
import Type2 from "./connectorTypeIcons/Type2";
import SCHUKO from "./connectorTypeIcons/SCHUKO";
import { useT } from "@utils/translation";
import Message from "@components/toasts/Message";
import StatusDisplayLabel from "./StatusDisplayLabel";
import WarningIndicator from "./plugInIllustrations/Warning";
import PlugCar from "./plugInIllustrations/PlugCar";
import StationPlaceholder from "./StationPlaceholder";
import { useStation } from "./state";
import ChargingInfo from "@features/ChargingProcess/ChargingInfo";
import { useChargingProcess } from "@features/ChargingProcess/state";

export default function StationInfo() {
  const { t } = useT();
  const station = useStation((s) => s.station);
  const connector = useStation((s) => s.connector);
  const isStationLoading = useStation((s) => s.isStationLoading);
  const getStationData = useStation((s) => s.getStationData);
  const isCharging = useChargingProcess((s) => !!s.chargingProcessId);
  const hasFinishedCharging = useChargingProcess((s) => s.hasFinishedCharging);

  const isOccupied = [
    ConnectorStatus.OCCUPIED,
    ConnectorStatus.UNAVAILABLE,
  ].includes(connector?.status || ConnectorStatus.UNAVAILABLE);

  const connectorTypeIcon = useMemo(() => {
    switch (connector?.connectorType) {
      case ConnectorType.TYP2:
      case ConnectorType.TYP2_CABLE:
        return <Type2 className="size-6 -mb-[2px]" />;
      case ConnectorType.CCS:
        return <CCS className="size-6 -mb-px" />;
      case ConnectorType.CHAdeMO:
        return <CHADEMO className="size-6 -mb-[2px]" />;
      case ConnectorType.SCHUKO:
        return <SCHUKO className="size-6 -mb-[2px]" />;

      default:
        return null;
    }
  }, [connector?.connectorType]);

  const showConnectorSide = useMemo(
    () =>
      station
        ? station.numberOfEVSEs > 1 && station.serialNumber.startsWith("ALF_")
        : false,
    [station]
  );

  useEffect(() => {
    getStationData();
  }, [getStationData]);

  if (isStationLoading) {
    return <StationPlaceholder />;
  }

  if (!station || !connector) {
    return null;
  }

  return (
    <div className="w-full flex flex-col gap-6 md:gap-9">
      <div className="bg-slate-50 border border-gray-200 rounded-lg">
        <div className="flex pt-6 -mb-[2px]">
          <div className="flex flex-col items-center pt-1 w-16 shrink-0">
            <MapPin className="text-2xl -mb-[2px]" weight="bold" />
            <VerticalLine />
          </div>

          <div className="flex flex-col pb-6 pr-4">
            <h1 className="text-2xl font-medium">{station.name}</h1>

            <div className="font-medium text-xs text-medium-smoke">
              {station.address.street}, {station.address.postalCode}{" "}
              {station.address.city}
            </div>
          </div>
        </div>

        <div className="flex -mb-1">
          <div className="flex flex-col items-center w-16 shrink-0">
            {connectorTypeIcon}
            <VerticalLine />
          </div>

          <div className="flex flex-col pb-6">
            <div className="flex items-center pt-0.5">
              <div className="font-bold text-sm">
                {connector.loadCapacity} kW
              </div>
              <div className="font-bold text-sm">&ensp;&#x2022;&ensp;</div>
              <div className="font-bold text-sm">
                {connector.connectorType}
                {showConnectorSide &&
                  (connector.connectorId === "1" ? (
                    <> ({t("connector.locationLeft")})</>
                  ) : connector.connectorId === "2" ? (
                    <> ({t("connector.locationRight")})</>
                  ) : (
                    connector.connectorId
                  ))}
              </div>
              <div className="font-bold text-sm">&ensp;&#x2022;&ensp;</div>
              <div className="font-bold text-sm">
                {station.operator.shortName}
              </div>
            </div>

            <div className="text-xs font-medium text-dark-smoke pt-0.5 pr-4">
              {connector.priceInfo.isFree
                ? t("tariffIsFree")
                : t("tariffLabel") + ": " + connector.priceInfo.tariffName}
            </div>
          </div>
        </div>

        <div className="flex relative">
          <div
            className={clsx("pl-4 flex items-center gap-4", {
              "pb-6": ![
                ConnectorStatus.UNAVAILABLE,
                ConnectorStatus.OCCUPIED,
              ].includes(connector.status),
              "pb-4": [
                ConnectorStatus.UNAVAILABLE,
                ConnectorStatus.OCCUPIED,
              ].includes(connector.status),
            })}
          >
            <StatusDisplayLabel status={connector.status} className="pl-12" />

            {hasFinishedCharging && (
              <span className="text-sm font-medium text-medium-green">
                {t("hasFinishedCharging")}
              </span>
            )}
          </div>

          <div className="absolute h-full flex flex-col items-center w-16">
            <VerticalLine
              className={clsx({
                "!h-3": [
                  ConnectorStatus.OCCUPIED,
                  ConnectorStatus.UNAVAILABLE,
                ].includes(connector.status),
              })}
            />

            <div
              className={clsx("absolute w-1.5 h-1.5 rounded-full bg-black", {
                "top-[16px]": connector.status === ConnectorStatus.CHARGING,
                "top-[11px]": connector.status !== ConnectorStatus.CHARGING,
              })}
            />
          </div>
        </div>

        {!isCharging && !isOccupied && (
          <div className="px-1">
            <div className="flex items-center p-4 pl-3 gap-4 justify-between bg-slate-200 rounded overflow-hidden relative">
              <div className="flex items-center gap-4 pl-0.5">
                <WarningIndicator />

                <span className="font-semibold text-sm md:text-base">
                  {t("pluginInfoText")}
                </span>
              </div>

              <PlugCar />
            </div>
          </div>
        )}

        <ChargingInfo />
      </div>

      {isOccupied && !isCharging && (
        <Message
          message={t("infoMessageOccupied")}
          color="yellow"
          icon={Warning}
        />
      )}
    </div>
  );
}

function VerticalLine({ className, ...props }: HTMLProps<HTMLDivElement>) {
  return (
    <div className="flex-1 w-full flex flex-col items-center" {...props}>
      <div className={clsx("h-full w-[2px] bg-black", className)} />
    </div>
  );
}
