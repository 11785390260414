import { inter, officina } from "@pages/_app";
import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  Transition,
  TransitionChild,
} from "@headlessui/react";
import clsx from "clsx";
import { Fragment, HTMLProps, ReactNode } from "react";

interface Props extends HTMLProps<HTMLDivElement> {
  open: boolean;
  onClose: () => void;
  children?: ReactNode;
}

export default function Modal({ open, onClose, children, className }: Props) {
  return (
    <Transition as={Fragment} show={open}>
      <Dialog
        onClose={onClose}
        className={`fixed top-0 left-0 w-screen h-screen flex items-center justify-center z-50 p-1 ${inter.variable} ${officina.variable} font-sans outline-none`}
      >
        <TransitionChild
          as={Fragment}
          enter="transition-opacity ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <DialogBackdrop className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur backdrop-filter z-30" />
        </TransitionChild>

        <TransitionChild
          as={Fragment}
          enter="transition ease-out duration-300"
          enterFrom="translate-y-16 opacity-0"
          enterTo="translate-y-0 opacity-100"
          leave="transition ease-in duration-200"
          leaveFrom="translate-y-0 opacity-100"
          leaveTo="translate-y-16 opacity-0"
        >
          <DialogPanel
            className={clsx(
              "flex flex-col bg-white border border-gray-300 rounded-xl shadow-2xl z-50",
              "max-h-full",
              className
            )}
          >
            {children}
          </DialogPanel>
        </TransitionChild>
      </Dialog>
    </Transition>
  );
}
