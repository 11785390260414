import { SVGProps } from "react";

export default function SCHUKO(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      id="svg2"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 32 32"
      xmlSpace="preserve"
      {...props}
    >
      <style type="text/css">
        {
          "\n\t.st0{fill:none;stroke:#000000;stroke-width:2.5;stroke-linejoin:round;stroke-miterlimit:17.3171;}\n"
        }
      </style>
      <g>
        <path
          className="st0"
          d="M27.4,13.6V9.2c-2.1-3.5-5.7-5.9-9.9-6.4v1.8h-3.2V2.8c-4.2,0.5-7.8,2.9-9.9,6.4v4.4H2.7v4.8h1.9v4.4 c2.1,3.5,5.7,5.9,9.9,6.4v-1.8h3.2v1.8c4.2-0.5,7.8-2.9,9.9-6.4v-4.4h1.9v-4.8H27.4z"
        />
        <path d="M11.6,18.1c-1.1,0-2-0.9-2-2c0-1.1,0.9-2,2-2c1.1,0,2,0.9,2,2C13.6,17.2,12.7,18.1,11.6,18.1z" />
        <path d="M20.5,18.1c-1.1,0-2-0.9-2-2c0-1.1,0.9-2,2-2c1.1,0,2,0.9,2,2C22.5,17.2,21.6,18.1,20.5,18.1z" />
      </g>
    </svg>
  );
}
