import { i18n } from "next-i18next";

export function formatNumber(
  value?: number | string,
  fractionDigits?: {
    max?: number;
    min?: number;
  },
  unit?: string
) {
  let numberValue =
    typeof value === "undefined"
      ? 0
      : typeof value === "string"
      ? parseFloat(value)
      : value;

  if (isNaN(numberValue)) {
    numberValue = 0;
  }

  return (
    new Intl.NumberFormat(i18n?.language, {
      maximumFractionDigits: fractionDigits?.max,
      minimumFractionDigits: fractionDigits?.min,
      minimumIntegerDigits: 1,
    }).format(numberValue) + (unit ? ` ${unit}` : "")
  );
}
