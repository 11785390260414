import { useMemo } from "react";
import { ConnectorStatus } from "@utils/types/ConnectorStatus.enum";
import Badge from "@components/Badge";
import { useT } from "@utils/translation";
import BatteryIndicator from "./BatteryIndicator";
import clsx from "clsx";

interface Props {
  status: ConnectorStatus | null;
  className?: string;
}

export default function StatusDisplayLabel({ status, className }: Props) {
  const { t } = useT();

  const statusDisplayLabel = useMemo(() => {
    switch (status) {
      case ConnectorStatus.AVAILABLE:
        return t("connector.state.available");
      case ConnectorStatus.CHARGING:
        return t("connector.state.charging");
      case ConnectorStatus.FINISHING:
        return t("connector.state.finishing");
      case ConnectorStatus.OCCUPIED:
        return t("connector.state.occupied");
      case ConnectorStatus.UNAVAILABLE:
      default:
        return t("connector.state.unavailable");
    }
  }, [status, t]);

  const color = useMemo(() => {
    switch (status) {
      case ConnectorStatus.AVAILABLE:
        return "green";
      case ConnectorStatus.UNAVAILABLE:
      case ConnectorStatus.OCCUPIED:
        return "red";
      case ConnectorStatus.CHARGING:
        return "blue";
      case ConnectorStatus.FINISHING:
        return "orange";
      default:
        return undefined;
    }
  }, [status]);

  return (
    status && (
      <Badge
        className={clsx("flex items-center gap-3", className)}
        color={color}
      >
        {statusDisplayLabel}{" "}
        {status === ConnectorStatus.CHARGING && <BatteryIndicator />}
      </Badge>
    )
  );
}
