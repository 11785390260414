import { datadogRum } from "@datadog/browser-rum";
import { toast } from "@components/toasts/state";
import { DD_ACTION, DD_ERROR } from "@utils/datadog";
import { useState } from "react";
import { GpsSlash } from "@phosphor-icons/react";
import { useT } from "@utils/translation";
import Button from "@components/Button";
import Spinner from "@components/Spinner";
import { api } from "@utils/api";
import { parseClientError } from "@utils/errors";

interface Props {
  scannedEvseId: string | null;
  onDismiss: () => void;
}

export default function PlaceNotFoundFeedback({
  scannedEvseId,
  onDismiss,
}: Props) {
  const [feedbackText, setFeedbackText] = useState<string | null>(null);
  const [sendingFeedback, setSendingFeedback] = useState(false);
  const { t } = useT();

  const onSendFeedback = async () => {
    let location;
    setSendingFeedback(true);

    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        async function (position) {
          location = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };

          try {
            await api.post("/station/placeNotFound", {
              evseId: scannedEvseId,
              location,
              feedbackText,
            });
            datadogRum.addAction(DD_ACTION.QR_SCAN_FEEDBACK_SENT, {
              evseId: scannedEvseId,
            });
            onDismiss();
          } catch (error) {
            parseClientError(error, "PLACE_NOT_FOUND_FEEDBACK");
            datadogRum.addError(DD_ERROR.QR_SCAN_SEND_FEEDBACK, {
              evseId: scannedEvseId,
              location,
              feedbackText,
            });
          } finally {
            setSendingFeedback(false);
          }
        },
        (geolocationError) => {
          console.error(
            "QR_PLACE_NOT_FOUND_FEEDBACK_LOCATION_ERROR",
            geolocationError
          );
          if (geolocationError.code === geolocationError.PERMISSION_DENIED) {
            toast.error({
              icon: GpsSlash,
              message: t("scanError.placeNotFound.locationNotEnabledError"),
            });
          } else {
            toast.error({
              icon: GpsSlash,
              message: t("scanError.placeNotFound.locationError"),
            });
          }
          setSendingFeedback(false);
        }
      );
    } else {
      toast.error({
        icon: GpsSlash,
        message: t("scanError.placeNotFound.locationError"),
      });
    }
  };

  return (
    <div className="flex flex-col gap-4 mb-2 mt-2">
      <label className="w-full flex flex-col gap-2">
        <span className="text-sm">{t("scanError.placeNotFound.label")}</span>
        <textarea
          onChange={(e) => setFeedbackText(e.target.value)}
          className="rounded-lg px-4 py-2 md:py-3 bg-white text-dark-berry resize-y min-h-24 text-base"
          placeholder={t("scanError.placeNotFound.placeholder")}
        />
      </label>

      <div className="flex gap-2">
        <Button
          disabled={sendingFeedback}
          onClick={onSendFeedback}
          color="red"
          className="text-white flex-1"
        >
          {t("scanError.placeNotFound.sendFeedback")}
          {sendingFeedback && <Spinner />}
        </Button>

        <Button onClick={onDismiss} color="transparent">
          {t("cancel")}
        </Button>
      </div>
    </div>
  );
}
