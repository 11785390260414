import clsx from "clsx";
import { HTMLProps } from "react";
import { useT } from "@utils/translation";

function Title({ className, ...props }: HTMLProps<HTMLParagraphElement>) {
  return <p className={clsx("text-gray-400 mb-0.5", className)} {...props} />;
}

function Paragraph({ className, ...props }: HTMLProps<HTMLParagraphElement>) {
  return <p className={clsx("text-black", className)} {...props} />;
}

function Badge({ className, ...props }: HTMLProps<HTMLDivElement>) {
  return (
    <div
      className={clsx("border border-gray-200 rounded-md p-2", className)}
      {...props}
    />
  );
}

function BadgeContainer({ className, ...props }: HTMLProps<HTMLDivElement>) {
  return (
    <div className={clsx("flex flex-wrap gap-2 my-1", className)} {...props} />
  );
}

function Anchor({ className, ...props }: HTMLProps<HTMLAnchorElement>) {
  return (
    <a
      className={clsx("underline text-black", className)}
      target="_blank"
      {...props}
    />
  );
}

function List({ className, ...props }: HTMLProps<HTMLUListElement>) {
  return (
    <ul
      className={clsx("list-disc list-inside pl-1 pt-1", className)}
      {...props}
    />
  );
}

export default function Datadog() {
  const { t } = useT("consent");

  return (
    <div className="flex flex-col gap-4">
      <section>
        <Title>{t("datadog.title1")}</Title>
        <Paragraph>{t("datadog.desc1")}</Paragraph>
      </section>

      <section>
        <Title>{t("datadog.title2")}</Title>
        <Paragraph>{t("datadog.desc2.1")}</Paragraph>
        <Paragraph>{t("datadog.desc2.2")}</Paragraph>
      </section>

      <section>
        <Title>{t("datadog.title3")}</Title>
        <Paragraph>{t("datadog.desc3")}</Paragraph>
        <Anchor href="mailto:gdpr@datadoghq.com">gdpr@datadoghq.com</Anchor>
      </section>

      <section>
        <Title>{t("datadog.title4")}</Title>
        <Paragraph>{t("datadog.desc4")}</Paragraph>
        <BadgeContainer>
          <Badge>{t("datadog.badge4.1")}</Badge>
          <Badge>{t("datadog.badge4.2")}</Badge>
          <Badge>{t("datadog.badge4.3")}</Badge>
        </BadgeContainer>
      </section>

      <section>
        <Title>{t("datadog.title5")}</Title>
        <Paragraph>{t("datadog.desc5")}</Paragraph>
        <BadgeContainer>
          <Badge>{t("datadog.badge5")}</Badge>
        </BadgeContainer>
      </section>

      <section>
        <Title>{t("datadog.title6")}</Title>
        <Paragraph>{t("datadog.desc6")}</Paragraph>
        <BadgeContainer>
          <Badge>{t("datadog.badge6.1")}</Badge>
          <Badge>{t("datadog.badge6.2")}</Badge>
          <Badge>{t("datadog.badge6.3")}</Badge>
          <Badge>{t("datadog.badge6.4")}</Badge>
          <Badge>{t("datadog.badge6.5")}</Badge>
        </BadgeContainer>
      </section>

      <section>
        <Title>{t("datadog.title7")}</Title>
        <Paragraph>{t("datadog.desc7")}</Paragraph>
        <BadgeContainer>
          <Badge>{t("datadog.badge7")}</Badge>
        </BadgeContainer>
      </section>

      <section>
        <Title>{t("datadog.title8")}</Title>
        <Paragraph>{t("datadog.desc8")}</Paragraph>
        <List>
          <li>{t("datadog.list8")}</li>
        </List>
      </section>

      <section>
        <Title>{t("datadog.title9")}</Title>
        <Paragraph>{t("datadog.desc9")}</Paragraph>
        <List>
          <li>{t("datadog.list9")}</li>
        </List>
      </section>

      <section>
        <Title>{t("datadog.title10")}</Title>
        <Paragraph>{t("datadog.desc10")}</Paragraph>
        <BadgeContainer>
          <Badge>{t("datadog.list10")}</Badge>
        </BadgeContainer>
      </section>

      <section>
        <Title>{t("datadog.title11")}</Title>
        <Paragraph>{t("datadog.desc11")}</Paragraph>
        <BadgeContainer>
          <Badge>{t("datadog.badge11")}</Badge>
        </BadgeContainer>
      </section>

      <section>
        <Title>{t("datadog.title12")}</Title>
        <Anchor href="https://www.datadoghq.com/legal/privacy/">
          https://www.datadoghq.com/legal/privacy/
        </Anchor>
      </section>

      <section>
        <Title>{t("datadog.title13")}</Title>
        <Anchor href="https://www.datadoghq.com/legal/cookies/">
          https://www.datadoghq.com/legal/cookies/
        </Anchor>
      </section>

      <section>
        <Title>{t("datadog.title14")}</Title>
        <Paragraph>{t("datadog.desc14")}</Paragraph>
        <List>
          <li>{t("datadog.list14.1")}</li>
          <li>{t("datadog.list14.2")}</li>
        </List>
      </section>
    </div>
  );
}
