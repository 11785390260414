import { useMemo } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { getStripeElementsConfig, stripePromise } from "@utils/stripe";
import CheckoutForm from "@features/ChargingProcess/PaymentForm/PaymentForm";
import { WarningCircle } from "@phosphor-icons/react";
import { useT } from "@utils/translation";
import { GetStaticProps } from "next";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import QRScanner from "@features/QRScanner/QRScanner";
import { ConnectorStatus } from "@utils/types/ConnectorStatus.enum";
import StationInfo from "@features/Station/StationInfo";
import ConsentManagement from "@features/ConsentManagement/ConsentManagement";
import Message from "@components/toasts/Message";
import { useStation } from "@features/Station/state";
import { useChargingProcess } from "@features/ChargingProcess/state";

export default function Home() {
  const { t } = useT();
  const amount = useChargingProcess((s) => s.amount);
  const chargingProcessId = useChargingProcess((s) => s.chargingProcessId);
  const station = useStation((s) => s.station);
  const connector = useStation((s) => s.connector);
  const isScanning = useStation((s) => s.isScanning);

  const showPaymentForm = useMemo(
    () =>
      (station?.directPaymentEnabled || Boolean(connector?.priceInfo.isFree)) &&
      !chargingProcessId &&
      connector &&
      connector?.status === ConnectorStatus.AVAILABLE,
    [chargingProcessId, connector, station]
  );

  return (
    <div className="w-full flex-1 flex flex-col items-center overflow-y-auto">
      <div className="flex-1 max-w-full md:max-w-lg w-full flex flex-col items-center gap-6 md:gap-9 pb-24 px-4 md:px-px relative">
        <QRScanner />

        {!isScanning && (
          <>
            <StationInfo />

            {showPaymentForm && (
              <Elements
                options={getStripeElementsConfig(amount)}
                stripe={stripePromise}
              >
                <CheckoutForm />
              </Elements>
            )}

            {station && !station?.directPaymentEnabled && (
              <Message
                message={t("error.directPaymentNotPossible")}
                color="red"
                icon={WarningCircle}
              />
            )}
          </>
        )}

        <ConsentManagement />
      </div>
    </div>
  );
}

export const getStaticProps: GetStaticProps = async ({ locale }) => {
  return {
    props: {
      ...(await serverSideTranslations(locale || "de", ["common", "consent"])),
    },
  };
};
