import Button from "@components/Button";
import Modal from "@components/Modal";
import { useT } from "@utils/translation";

interface Props {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

export default function StopChargingConfirmation({
  open,
  onClose,
  onConfirm,
}: Props) {
  const { t } = useT();

  return (
    <Modal open={open} onClose={onClose} className="p-6 max-w-md">
      <h3 className="font-semibold text-2xl mb-12">
        {t("stopChargingConfirmationTitle")}
      </h3>

      <div className="flex items-center gap-6">
        <Button
          color="red"
          className="flex-1 justify-center"
          onClick={onConfirm}
        >
          {t("actionButtonLabelStop")}
        </Button>

        <Button color="transparent" onClick={onClose}>
          {t("cancel")}
        </Button>
      </div>
    </Modal>
  );
}
