export default function StationPlaceholder() {
  return (
    <div className="w-full flex flex-col animate-pulse bg-gray-50 border border-gray-200 rounded-lg">
      <div className="pr-5 pl-16 py-8 relative flex flex-col gap-2">
        <div className="bg-gray-200 max-w-sm h-9 rounded-lg" />
        <div className="bg-gray-100 w-1/2 h-5 rounded-lg mb-6" />

        <div className="bg-gray-200 max-w-sm h-5 rounded-lg" />
        <div className="bg-gray-100 w-1/2 h-5 rounded-lg" />

        <div className="absolute w-0.5 bg-gray-300 top-12 -bottom-2 left-8 rounded-full" />

        <div className="absolute w-3 h-3 bg-gray-300 left-8 top-11 transform -translate-x-[5px] rounded-full" />
      </div>

      <div className="p-1 z-10">
        <div className="bg-light-smoke rounded h-16" />
      </div>
    </div>
  );
}
