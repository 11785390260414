import { useEffect, useRef } from "react";
import Image from "next/image";
import { useT } from "@utils/translation";
import QrScanner from "qr-scanner";
import clsx from "clsx";
import { CameraSlash, QrCode, X } from "@phosphor-icons/react";
import { datadogRum } from "@datadog/browser-rum";
import { api } from "@utils/api";
import { getConnectorIdFromEVSEId } from "@utils/chargeStation";
import { DD_ACTION, DD_GLOBAL_PROPERTY } from "@utils/datadog";
import Button from "@components/Button";
import { toast } from "@components/toasts/state";
import PlaceNotFoundFeedback from "./PlaceNotFoundFeedback";
import InvalidEvseError from "./InvalidEvseError";
import { useStation } from "../Station/state";
import { useChargingProcess } from "@features/ChargingProcess/state";

const notUsedUrls = [
  "linktr.ee",
  "www.lichtblick.de",
  "www.eneco-emobility.com",
  "www.chargeit-mobility.com",
];

export default function QRScanner() {
  const { t } = useT();

  const videoRef = useRef<HTMLVideoElement>(null);
  const qrScanner = useRef<QrScanner | null>(null);
  const previousScannedResult = useRef<string | null>(null);
  const scanTimeout = useRef<NodeJS.Timeout | null>(null);
  const setStationState = useStation((s) => s.setStationState);
  const isScanning = useStation((s) => s.isScanning);
  const isStationLoading = useStation((s) => s.isStationLoading);
  const station = useStation((s) => s.station);
  const setSerialAndConnector = useStation((s) => s.setSerialAndEvseId);
  const chargingProcessId = useChargingProcess((s) => s.chargingProcessId);

  function closeScanner() {
    setStationState({ isScanning: false });
    qrScanner.current?.destroy();
  }

  async function handleScanResult(scannedUrl: string) {
    if (notUsedUrls.find((url) => scannedUrl.includes(url))) {
      datadogRum.addAction(DD_ACTION.QR_SCAN_ERROR_WRONG_URL, {
        scannedUrl,
      });
      toast.error({
        message: t("scanError.wrongQrCode"),
      });
      return;
    }

    let evseId: string | null = null;
    try {
      const searchParams = new URL(scannedUrl).searchParams;
      evseId = searchParams.get("evseid") || searchParams.get("evseId");
    } catch (e) {
      toast.error({
        message: t("scanError.noEVSE"),
      });
    }

    if (!evseId) {
      datadogRum.addAction(DD_ACTION.QR_SCAN_ERROR_EVSE_NOT_IN_URL, {
        scannedUrl,
      });

      toast.error({
        message: t("scanError.noEVSE"),
      });

      return;
    }

    try {
      const { data: serialNumber } = await api.get<string>(
        "/station/getSerialFromEvseId",
        { params: { evseId }, headers: { "cache-control": "no-cache" } }
      );

      closeScanner();
      setSerialAndConnector({ serialNumber, evseId });

      datadogRum.setGlobalContextProperty(
        DD_GLOBAL_PROPERTY.HAS_SCANNED_QR_CODE,
        true
      );

      datadogRum.addAction(DD_ACTION.QR_SCAN_SUCCESS, {
        serialNumber,
        evseId,
      });
    } catch (e: any) {
      const errorMessage = e?.response?.data;

      switch (errorMessage) {
        case "EVSE_OPERATOR_NOT_SERVED": {
          datadogRum.addAction(
            DD_ACTION.QR_SCAN_ERROR_EVSE_OPERATOR_NOT_SERVED,
            { scannedUrl }
          );

          toast.error({
            message: t("scanError.notServedEVSE"),
          });
          break;
        }
        case "PLACE_NOT_FOUND": {
          datadogRum.addAction(DD_ACTION.QR_SCAN_ERROR_PLACE_NOT_FOUND, {
            scannedUrl,
          });

          toast.error({
            isStatic: true,
            isDismissable: false,
            message: t("scanError.placeNotFound.message"),
            children: (onDismiss) => (
              <PlaceNotFoundFeedback
                scannedEvseId={evseId}
                onDismiss={onDismiss}
              />
            ),
          });
          closeScanner();
          break;
        }
        case "EVSEID_INVALID": {
          datadogRum.addAction(DD_ACTION.QR_SCAN_ERROR_EVSEID_INVALID, {
            scannedUrl,
          });

          toast.error({
            message: t("scanError.invalidEVSE"),
            children: (onDismiss) => <InvalidEvseError onDismiss={onDismiss} />,
            isStatic: true,
          });
          closeScanner();
          break;
        }
        default: {
          datadogRum.addAction(DD_ACTION.QR_SCAN_ERROR_UNKNOWN, {
            data: errorMessage,
            scannedUrl,
          });

          toast.error({
            message: t("scanError.otherError"),
          });
          break;
        }
      }
    }
  }

  const onScan = async () => {
    if (!videoRef.current) return;

    setStationState({ isScanning: true });
    datadogRum.addAction(DD_ACTION.QR_SCAN_START);

    qrScanner.current = new QrScanner(
      videoRef.current,
      async (result) => {
        // clear previous timeout
        if (scanTimeout.current) {
          clearTimeout(scanTimeout.current);
        }
        // add a timeout so that the previousEvseId is reset after 1 second
        // in order to let the user scan the code again if needed
        scanTimeout.current = setTimeout(() => {
          previousScannedResult.current = null;
        }, 500);

        if (result.data === previousScannedResult.current) {
          return;
        }

        previousScannedResult.current = result.data;

        handleScanResult(result.data);
      },
      {
        returnDetailedScanResult: true,
        highlightScanRegion: true,
        highlightCodeOutline: true,
        onDecodeError: (e) => {},
      }
    );

    try {
      await qrScanner.current?.start();
    } catch (e) {
      toast.error({
        icon: CameraSlash,
        message: t("cameraNotAvailable"),
        isStatic: true,
      });
      console.error(e);
    }
  };

  useEffect(() => {
    QrScanner.hasCamera().then(
      (hasCamera) =>
        !hasCamera &&
        toast.error({
          icon: CameraSlash,
          message: t("cameraNotAvailable"),
          isStatic: true,
        })
    );
  }, [t]);

  return (
    <div
      className={clsx("self-stretch flex flex-col items-end", {
        "py-9": (!Boolean(station) || isScanning) && !isStationLoading,
      })}
    >
      <div
        className={clsx("flex flex-col items-center gap-9", {
          hidden: isScanning || isStationLoading || Boolean(station),
        })}
      >
        <div className="flex flex-col gap-6 text-center">
          <h3 className="text-3xl font-header">{t("scanHeadline")}</h3>

          <div className="font-medium">
            <h5 className="px-10 mb-1">{t("scanDesc1")}</h5>
            <h5>{t("scanDesc2")}</h5>
          </div>
        </div>

        <div
          className={clsx(
            "flex flex-col items-center gap-4 rounded-2xl p-8 pb-2 shadow-md border border-gray-50"
          )}
        >
          <Image
            width={249}
            height={126}
            src="/images/code.png"
            alt="intercharge-code-example"
            className="max-h-24 w-auto object-contain"
          />

          <div className="text-gray-500">{t("example")}</div>
        </div>

        <Button
          disabled={isScanning}
          className="self-stretch justify-center"
          onClick={onScan}
        >
          <QrCode className="w-6 h-6" /> {t("scanQRCode")}
        </Button>
      </div>

      <div
        className={clsx("relative w-full", {
          hidden: !isScanning,
        })}
      >
        <video className="w-full rounded-md" ref={videoRef} />

        <Button
          color="transparent"
          className="w-12 h-12 !bg-gray-100 !bg-opacity-80 hover:!bg-opacity-100 active:!bg-gray-300 text-black !rounded-full absolute right-2 top-2 z-10 justify-center !p-0"
          onClick={closeScanner}
        >
          <X size={24} weight="bold" />
        </Button>
      </div>

      <Button
        color="gray"
        className={clsx(
          "w-16 h-16 !rounded-full fixed right-4 lg:right-auto bottom-4 justify-center !p-0 shadow-xl z-30 border border-gray-200",
          { hidden: isScanning || !Boolean(station) || chargingProcessId }
        )}
        onClick={onScan}
      >
        <QrCode size={36} />
      </Button>
    </div>
  );
}
